.RegisterPage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RegisterForm {
  margin-top: 5vh;
  padding: 40px;
  border: 1px solid grey;
  border-radius: 10px;
  width: 40%;
}

.RegisterForm h1 {
  margin-bottom: 30px;
}
