.SidePaneBrowser {
  flex: 1;
  overflow: hidden;
}

.SidePaneList {
  display: flex;
  overflow-y: scroll;
  height: 100%;
  flex-direction: column;
}

.ListGroupSpinner {
  margin-top: auto;
  margin-bottom: auto;
  align-self: center;
}

.SidePaneRow {
  cursor: pointer;
}

.SidePaneFooter {
  margin-top: auto;
}

.SidePaneFooterPagination {
  height: 100%;
  justify-content: center;
  align-items: center;
}
