.LoginPage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginForm {
  margin-top: 15vh;
  padding: 40px;
  border: 1px solid grey;
  border-radius: 10px;
}

.LoginForm h1 {
  margin-bottom: 30px;
}
