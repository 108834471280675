.InstacleanKpi {
  /* flex: 1; */
  width: 100vw;
  overflow: auto;
  /* margin-inline: auto; */
}

.kpi-container {
  height: 100%;
  /* width: 100dvw; */

  display: flex;
}

.half {
  width: 50%;
  /* border-right: 2px solid rgb(208, 208, 208);
  border-left: 2px solid rgb(208, 208, 208); */
  padding: 20px;
}

.daily-user__input {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f0f0f0;
}

.daily-user__input input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.daily-user__input button {
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.daily-user__input button:disabled {
  opacity: 0.6;
}

.daily-user__table {
  /* flex-grow: 1; */
  overflow: hidden;
  border: 1px solid #000;
}

.daily-user__table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000;
}

.daily-user__table th,
.daily-user__table td {
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
}

.daily-user__table th {
  background-color: #3498db;
  color: #fff;
}

.daily-user__table tbody tr:nth-child(even) {
  background-color: #eeeeee;
}

.daily-user__table tbody tr:hover {
  background-color: #e0e0e0;
}
