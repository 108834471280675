.RenderMasterMainPane {
  display: flex;
  height: 100%;
  width: calc(100% - 16rem);
  margin: 0 0.5rem;
  justify-content: space-between;
  border-radius: 10px;
}

.MainPaneIframeContainer {
  height: 100%;
  width: 55%;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 5px;
}

.MainPaneEditorContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 45%;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 5px;
}

.MainPaneIframe {
  height: 100%;
  width: 100%;
}

.MainPaneEditor {
  height: 100%;
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.EditorPane {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.btn_control {
  width: 100%;
  display: flex;
}

.DocumentationPane {
  height: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.CsvDownloadForm {
  margin-top: auto;
}

.HtmlPane {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.HtmlTextArea {
  flex-grow: 1;
  display: flex;
}

.CsvDownloadForm {
  margin-top: auto;
}
