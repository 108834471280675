.Navbar {
  height: 50px;
}

.NavbarText {
  margin-left: 20px;
}

.Navbar Button {
  margin-left: 20px;
}
